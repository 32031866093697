
















import { mixins } from 'nuxt-property-decorator'
import changeWebsiteTipMixin from '~/components/layouts/default/common/change-website-tip-mixin'

export default class ChangeSiteTip extends mixins(changeWebsiteTipMixin) {}
